import { Stack, ListItemText, Avatar, Box } from '@mui/material';
import { Player } from '@oskarjiang/matchi-types';

type Props = {
	participant: Player;
};

const hasNoName = (participant: Player) => !participant.firstName && !participant.lastName;

export const ListItem = ({ participant }: Props) => (
	<Box sx={{ m: 0.5 }}>
		<Stack direction="row" spacing={1} alignItems="center" key={participant.userId}>
			<Avatar src={participant.profileImageURL ?? undefined} style={{ width: '72px', height: '72px' }}>
				{hasNoName(participant) ? 'A' : `${participant.firstName?.charAt(0)}${participant.lastName?.charAt(0)}`}
			</Avatar>
			<ListItemText primary={hasNoName(participant) ? `Anonymous` : `${participant.firstName} ${participant.lastName}`} />
		</Stack>
	</Box>
);
