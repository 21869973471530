import { Activity, Occasion } from '@oskarjiang/matchi-types';

export const getOccasionsFromActivities = (activities?: Activity[], forDay?: Date): Occasion[] => {
	if (!activities) {
		return [];
	}
	return activities
		.flatMap((activity) => activity.occasions)
		.sort(sortByDate)
		.filter((occasion) => (forDay ? isOnTheSameDay(new Date(occasion.startTime), forDay) : occasion));
};

export const getDatesBetween = (startDays: number, endDays: number = 0) => {
	const startDate = new Date();
	startDate.setDate(startDate.getDate() + startDays);

	const endDate = new Date();
	endDate.setDate(endDate.getDate() + endDays);

	const datesBetween = [];
	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		datesBetween.push(new Date(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return datesBetween;
};

const sortByDate = (a: Occasion, b: Occasion) => {
	const dateA = new Date(a.startTime);
	const dateB = new Date(b.startTime);
	return dateA.getTime() - dateB.getTime();
};

const isOnTheSameDay = (date1: Date, date2: Date) => {
	return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const checkTimeRange = (occassion: Occasion): boolean => {
	const startTime = new Date(occassion.startTime);
	const startTimeLimit = { hours: 17, minutes: 0 };
	const startDateTime = new Date(startTime);
	startDateTime.setHours(startTimeLimit.hours, startTimeLimit.minutes, 0, 0);

	const endTime = new Date(occassion.endTime);
	const endTimeLimit = { hours: 21, minutes: 0 };
	const endDateTime = new Date(endTime);
	endDateTime.setHours(endTimeLimit.hours, endTimeLimit.minutes, 0, 0);

	return startTime >= startDateTime && endTime <= endDateTime;
};
