import { Box, Divider, List as MUIList, Skeleton } from '@mui/material';
import { Occasion, Player } from '@oskarjiang/matchi-types';
import { ListItem } from './ListItem';
import { baseUrl } from '../../constants/endpoints';
import { useQuery } from '@tanstack/react-query';
import { useFocusedOccasion } from '../../contexts/FocusedOccasionContext';

export const List = () => {
	const { focusedOccasion } = useFocusedOccasion();
	const { data, isLoading } = useQuery<Occasion>({
		queryKey: ['participants', focusedOccasion?.id],
		queryFn: () => fetch(`${baseUrl}/occasion/${focusedOccasion?.id}`).then((res) => res.json()),
	});

	return (
		<MUIList component="nav">
			{!isLoading ? (
				data?.occasionParticipants
					.sort((a: Player, b: Player) => {
						const lastNameA = a.lastName?.toUpperCase(); // Convert names to uppercase
						const lastNameB = b.lastName?.toUpperCase();
						if (!lastNameA) {
							return 1; // A comes after B
						}
						if (!lastNameB) {
							return -1; // A comes before B
						}
						if (lastNameA < lastNameB) {
							return -1; // A comes before B
						}
						if (lastNameA > lastNameB) {
							return 1; // A comes after B
						}
						return 0; // A and B are equal
					})
					.map((participant: Player, index: number) => (
						<>
							<ListItem participant={participant} key={participant.userId} />
							{(index + 1) % 4 === 0 && index + 1 !== data.occasionParticipants.length ? <Divider component="li" /> : <></>}
						</>
					))
			) : (
				<>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
					<Box sx={{ m: 0.5 }}>
						<Skeleton variant="rounded" width="100%" height={72} />
					</Box>
				</>
			)}
		</MUIList>
	);
};
