import { Typography, Link, Divider, Skeleton } from '@mui/material';
import { Facility } from '@oskarjiang/matchi-types';
import { useFocusedOccasion } from '../../contexts/FocusedOccasionContext';
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../../constants/endpoints';
import { useFocusedActivity } from '../../contexts/FocusedActivityContext';

export const Header = () => {
	const { focusedOccasion } = useFocusedOccasion();
	const { focusedActivity } = useFocusedActivity();
	const { data: facilities, isLoading } = useQuery<Facility[]>({
		queryKey: ['facilities'],
		queryFn: () => fetch(`${baseUrl}/facilities`).then((res) => res.json()),
	});
	return (
		<>
			<Typography variant="h5">{focusedActivity?.name}</Typography>
			<Typography variant="subtitle1">{`${focusedOccasion?.participants}/${focusedOccasion?.maxNumParticipants}`}</Typography>
			{isLoading ? (
				<Skeleton />
			) : (
				<Link href={facilities?.find((facilitiy) => facilitiy.id === focusedActivity?.facilityId)?.webUrl} variant="subtitle1">
					{facilities?.find((facilitiy) => facilitiy.id === focusedActivity?.facilityId)?.name}
				</Link>
			)}
			<Typography variant="subtitle2">{focusedOccasion?.startTime && new Date(focusedOccasion.startTime).toLocaleDateString()}</Typography>
			<Typography variant="subtitle2">
				{`${focusedOccasion?.startTime && new Date(focusedOccasion.startTime).toLocaleTimeString()} - ${
					focusedOccasion?.endTime && new Date(focusedOccasion.endTime).toLocaleTimeString()
				}`}
			</Typography>
			<Typography variant="subtitle2">{focusedOccasion?.basePrice}:-</Typography>
			<Divider />
			<Typography variant="body2">{focusedActivity?.teaser}</Typography>
		</>
	);
};
